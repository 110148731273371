<template>
    <section class="moment">
        <header class="header">
            <h3 class="title">Odcinek {{ info.episode }}</h3>
            <p class="subtitle">
                {{ info.timestamp.start }} - {{ info.timestamp.end }}
            </p>
        </header>
        
        <p>
            {{ info.content }}
        </p>
    </section>
</template>

<script>
export default {
    props: {
        info: Object
    }  
}
</script>

<style lang="scss" scoped>
.header {
    display: flex;
    justify-content: space-between;    
}

.moment {
    text-align: center;
    padding: 20px;
}

.title, .subtitle {
    margin: 0;
}

</style>