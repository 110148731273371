<template>
  <div class="site-wrapper">
    <Header />
    
    <form class="form" required>
      <div class="entry">
        <label for="serial_name" class="label">Wybierz serial</label>
        <select id="serial_name" class="field select" v-model="serial" required>
          <option value="" hidden>Wybierz serial</option>
          <option v-for="serial in series" :key="serial.key" :value="serial.key">{{ serial.name }}</option>
        </select>
      </div>
      <div class="entry">
        <label id="quote" class="label">Podaj cytat</label>
        <input type="text" id="quote" class="field" v-model="query" required>
      </div>
      <div class="entry">
        <button type="button" @click="search">Wyszukaj</button>
      </div>
    </form>

    <div class="results">
      <h2>Znalezione wyniki:</h2>
      <ul class="moments-list">
        <li v-for="result in results" :key="(result.episode, result.id)" class="moment-element">
          <Moment :info="result" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import _ from 'underscore'
import config from './config'
import Header from './components/Header'
import Moment from './components/Moment'

export default {
  components: {
    Header,
    Moment
  },
  data() {
    return {
      serial: '',
      query: '',
      series: null,
      results: []
    }
  },
  async mounted() {
    const data = await fetch(`${config.apiUrl}/series`)
    const json = await data.json()
    this.series = json.series
  },
  methods: {
    async search() {
      const data = await fetch(`${config.apiUrl}/search?query=${encodeURI(this.query)}&serial=${this.serial}`)
      const json = await data.json()
      this.results = _.sortBy(json.results, 'episode')
    }
  }
}
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.site-wrapper {
  width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.entry {
  width: 100%;
  padding: 10px;
}

.label {
  display: block;
  padding: 5px;
}

.field {
  width: 100%;
}

.results {
  margin-top: 40px;
}

.moments-list {
  padding: 0;
  max-height: 300px;
  overflow-y: auto;
}

.moment-element {
  list-style-type: none;
  padding: 0;
  margin: 0;

  $border-color: #ccc;
  border-top: none;
  border-bottom: 2px solid $border-color;
  border-left: 2px solid $border-color;
  border-right: 2px solid $border-color;

  &:first-child {
    border-top: 2px solid $border-color;
  }
}
</style>
